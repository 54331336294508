// Tables
//

@each $color, $value in $theme-colors {
  @if $color != primary and $color != light {
    @include appwork-table-row-variant('.table-#{$color}', $value);
  }
}

.table.table-fixed {
  table-layout: fixed;
}

.table th {
  font-weight: $font-weight-semibold;
}

// *******************************************************************************
// * Active

.table-active,
.table-active > th,
.table-active > td {
  border-color: rgba($black, .035);
  background-color: $table-active-bg;
}

.table-hover .table-active:hover,
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba-to-hex(rgba($table-active-bg, .99), $rgba-to-hex-bg-inverted);
}

// *******************************************************************************
// * Within card

.card-table {
  margin-bottom: 0;
  border: 0 !important;
}

.card,
.nav-tabs-top,
.nav-tabs-right,
.nav-tabs-bottom,
.nav-tabs-left {
  .table:not(.table-dark),
  .table:not(.table-dark) thead:not(.thead-dark) th,
  .table:not(.table-dark) tbody th,
  .table:not(.table-dark) td {
    border-color: rgba-to-hex($card-inner-border-color, $card-bg);
  }
}

.card-table > thead:first-child > tr:first-child,
.card-table > tbody:first-child > tr:first-child,
.card-table > tfoot:first-child > tr:first-child {
   > td,
   > th {
    border-top: 0;
  }
}

@include ltr-only {
  .card-table > thead > tr,
  .card-table > tbody > tr,
  .card-table > tfoot > tr {
     > td,
     > th {
      &:first-child {
        padding-left: $card-spacer-x;
        border-left: 0;
      }

      &:last-child {
        padding-right: $card-spacer-x;
        border-right: 0;
      }
    }
  }

  // Condenced
  .card-condenced .card-table > thead > tr,
  .card-condenced .card-table > tbody > tr,
  .card-condenced .card-table > tfoot > tr {
     > td,
     > th {
      &:first-child {
        padding-left: $card-spacer-x-sm;
        border-left: 0;
      }

      &:last-child {
        padding-right: $card-spacer-x-sm;
        border-right: 0;
      }
    }
  }
}

@include rtl-only {
  .card-table > thead > tr,
  .card-table > tbody > tr,
  .card-table > tfoot > tr {
     > td,
     > th {
      &:first-child {
        padding-right: $card-spacer-x;
        border-right: 0;
      }

      &:last-child {
        padding-left: $card-spacer-x;
        border-left: 0;
      }
    }
  }

  // Condenced
  .card-condenced .card-table > thead > tr,
  .card-condenced .card-table > tbody > tr,
  .card-condenced .card-table > tfoot > tr {
     > td,
     > th {
      &:first-child {
        padding-right: $card-spacer-x-sm;
        border-right: 0;
      }

      &:last-child {
        padding-left: $card-spacer-x-sm;
        border-left: 0;
      }
    }
  }
}
.table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 1rem; // This applies to the whole table
  width: 100%;
  box-shadow: 0 4px 1rem rgba(0, 0, 0, 0.1);
  border: 1px solid #dee2e6;

  th,
  td {
    border: none;
    padding: 8px; // Adjust padding as needed
  }

  > thead {
    background-color: #f8f9fa;
    text-transform: uppercase;
    // Apply the border-radius only to the top corners of the header
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    // Remove the bottom border from the header
    border-bottom: none;
    overflow: hidden; // Prevents content from overflowing the rounded corners

    > tr {
      > th {
        &:first-child {
          border-top-left-radius: 1rem; // Top left corner
          border-bottom-left-radius: 1rem; // Bottom left corner
        }
        &:last-child {
          border-top-right-radius: 1rem; // Top right corner
          border-bottom-right-radius: 1rem; // Bottom right corner
        }
        // Ensure th elements do not have a bottom border
        // border-bottom: none;
      }
    }
  }

  > tbody:last-child > tr:last-child {
    > td:first-child {
      border-bottom-left-radius: 1rem; // Bottom left corner of the last td in the last row
    }
    > td:last-child {
      border-bottom-right-radius: 1rem; // Bottom right corner of the last td in the last row
    }
  }
}
// Card-like table row
.table-row-card {
  .table {
    width: 100%;
    border-radius: 1rem; // Apply border-radius to the whole table
    border-collapse: separate; // Enable separate border handling
    border-spacing: 0 0.75rem; // Apply vertical spacing between rows
    border: none;
    box-shadow: none;
    margin-top: -1rem;

    thead {
      tr {
        th {
          padding: 1.5rem 1rem 1rem 1rem;
          background-color: #f8f9fa; // Header background color
          &:first-child {
            border-top-left-radius: 1rem; // Top left corner
          }
          &:last-child {
            border-top-right-radius: 1rem; // Top right corner
          }
          // Add transition for background color change
          transition: background-color 0.3s ease;
        }
      }
    }

    tbody {
      tr {
        margin-bottom: 1rem; // Space between rows
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border: 1px solid #dee2e6; // Slight border for definition
        border-radius: 1rem; // Rounded corners for the card-like look
        // transition: box-shadow 0.3s ease; // Add transition for shadow

        // &:hover {
        //   box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
        // }

        td {
          padding: 1rem 0.5rem; // Adjust padding as needed
          &:first-child {
            border-top-left-radius: 1rem; // Rounded top left corner
            border-bottom-left-radius: 1rem; // Rounded bottom left corner
          }
          &:last-child {
            border-top-right-radius: 1rem; // Rounded top right corner
            border-bottom-right-radius: 1rem; // Rounded bottom right corner
          }
          // Add transition for background color change
          transition: background-color 0.3s ease;
        }
      }

      // Apply striped effect to td instead of tr
      tr {
        &:nth-child(odd) {
          td {
            background-color: #ffffff; // Adjust as needed
          }
        }
        &:nth-child(even) {
          td {
            background-color: rgba(24,28,33,0.035); // Adjust as needed
          }
        }
        &.canceled-row {
          td {
            background-color: #ffd9d9 !important; // Use !important to override other styles
            // Add transition for background color change
            transition: background-color 0.3s ease;
          }
        }
        &.selected-row {
          td {
            background-color: #c8e6c9 !important; // Use !important to override other styles
            // Add transition for background color change
            transition: background-color 0.3s ease;
          }
        }
      }
    }
  }

  @media (min-width: 769px) {
    &.single-row {
      // Add specific styles for the table when it has only one row
      .table {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        transition: box-shadow 0.3s ease;
      }

      &:hover {
        .table {
          box-shadow: rgba(100, 100, 111, 0.3) 0px 10px 40px 0px;
        }
      }
    }
  }
}