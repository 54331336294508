.travelthru {

}

$primary-color: #F24F1D;
$link-hover-color: #f65617;
$link-color: #F24F1D;
$link-decoration: none;
$link-hover-decoration: none;

.travelthru a {
    color: $link-color;
    text-decoration: $link-decoration;
    background-color: transparent; // Remove the gray background on active links in IE 10.
}

.travelthru a:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
}

// .travelthru .tl-dot::before {
//     background-color: #88888850 !important;
// }