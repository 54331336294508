@import "../_appwork/include-dark";
@import "../_appwork/include-material";
@import "../_appwork/include";
@import "../_custom-variables/uikit";

$ui-statistics-icon-size: 2.875rem !default;
$ui-statistics-icon-font-size: 1.125rem !default;
$ui-feed-icon-size: 1.25rem !default;
$ui-feed-icon-font-size: 0.75rem !default;
$ui-activity-icon-size: 2.5rem !default;
$ui-activity-icon-font-size: 0.875rem !default;

// *******************************************************************************
// * Statistics icons

.ui-statistics-icon {
  width: $ui-statistics-icon-size;
  height: $ui-statistics-icon-size;
  font-size: $ui-statistics-icon-font-size;
  line-height: calc(#{$ui-statistics-icon-size} - #{$ui-icon-border-width * 2});

  &.border-0 {
    line-height: $ui-statistics-icon-size;
  }
}

// *******************************************************************************
// * Feed icons

.ui-feed-icon-container {
  position: relative;
}

.ui-feed-icon {
  position: absolute;
  top: 0;
  right: -$ui-feed-icon-size / 2;
  width: $ui-feed-icon-size;
  height: $ui-feed-icon-size;
  border: 0;
  font-size: $ui-feed-icon-font-size;
  line-height: $ui-feed-icon-size;

  @include feature-rtl {
    right: auto;
    left: -$ui-feed-icon-size / 2;
  }
}

// *******************************************************************************
// * Todo items

.ui-todo-item .custom-control-input:checked {
  ~ .custom-control-label {
    text-decoration: line-through;
  }

  ~ .ui-todo-badge,
  ~ .custom-control-label .ui-todo-badge {
    display: none;
  }
}

// *******************************************************************************
// * Activity icons

.ui-activity-icon {
  width: $ui-activity-icon-size;
  height: $ui-activity-icon-size;
  font-size: $ui-activity-icon-font-size;
  line-height: calc(#{$ui-activity-icon-size} - #{$ui-icon-border-width * 2});

  &.border-0 {
    line-height: $ui-activity-icon-size;
  }
}

@import "../_appwork/include";
@if $enable-light-style {
  .light-style {
    .ui-todo-item .custom-control-input:checked ~ .custom-control-label {
      color: $text-muted;
    }
  }
}

@import "../_appwork/include-material";
@if $enable-material-style {
  .material-style {
    .ui-todo-item .custom-control-input:checked ~ .custom-control-label {
      color: $text-muted;
    }
  }
}

@import "../_appwork/include-dark";
@if $enable-dark-style {
  .dark-style {
    .ui-todo-item .custom-control-input:checked ~ .custom-control-label {
      color: $text-muted;
    }
  }
}
